import { RetweetOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import * as React from 'react';
/**
 * The `content` at the index.
 */
const DebugPage = () => {
  return (
    <div
      style={{
        position: 'absolute',
        top: '50vh',
        width: '100vw',
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
      }}
    >
      <Button
        onClick={() => {
          // @ts-ignore
          window.location.reload(true);
        }}
        shape="round"
        icon={<RetweetOutlined />}
        danger
        size="large"
      >
        Ladda om sidan
      </Button>
    </div>
  );
};

export default DebugPage;
